// import './styles/antd.style.less';
import './styles/styles.scss';

import { ConfigProvider } from 'antd';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import PublicRoute from './features/startup/public_route/PublicRoute';
import FullPageLoader from './foundation/components/full_page_loader/FullPageLoader.index';
import NotFound from './foundation/components/not_found/NotFound';
import { injectStore } from './foundation/config/axios';
import { useClientIP } from './foundation/cutom_hooks/useClientIP';
import reportWebVitals from './reportWebVitals';
import { store } from './store';

injectStore(store);

const Startup = lazy(() => import('./features/startup/Startup'));
const AgencyLeadAutoSignup = lazy(
  () =>
    import(
      './features/property_strategy/agency_lead_auto_signup/AgencyLeadAutoSignup'
    ),
);

const Login = lazy(() => import('./features/authentication/login/Login'));
const Signup = lazy(() => import('./features/authentication/signup/Signup'));
const ResetPassword = lazy(
  () => import('./features/authentication/reset_password/ResetPassword'),
);
const AgencyLeadAccess = lazy(
  () =>
    import('./features/property_strategy/agency_lead_access/AgencyLeadAccess'),
);
const AutoSignupSuccess = lazy(
  () =>
    import(
      './features/property_strategy/agency_lead_auto_signup/auto_signup_success/AutoSignupSuccess'
    ),
);

const ReportAccess = lazy(
  () => import('./features/ai_report/report_access/ReportAccess'),
);
const DeleteAccount = lazy(
  () => import('./features/authentication/delete_account/DeleteAccount'),
);
const PrivacyPolicy = lazy(
  () => import('./features/static_content/privacy_policy/PrivacyPolicy'),
);
const TermsConditions = lazy(
  () => import('./features/static_content/terms_conditions/TermsConditions'),
);

const Root = () => {
  /**
   * We need client ip on almost every route public and private both. That's why fetching it here.
   */
  useClientIP();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00b2a3',
        },
      }}
    >
      <Suspense fallback={<FullPageLoader />}>
        <Switch>
          <PublicRoute path="/login" strict exact component={Login} />
          <PublicRoute path="/invite" strict exact component={Signup} />
          <PublicRoute
            path="/reset-password"
            strict
            exact
            component={ResetPassword}
          />
          <PublicRoute
            path="/remi/nonclient/report"
            strict
            exact
            component={ReportAccess}
          />
          <PublicRoute
            path="/agency-lead"
            strict
            exact
            component={AgencyLeadAccess}
          />
          <PublicRoute
            path="/invite-lead"
            strict
            exact
            component={AgencyLeadAutoSignup}
          />
          <PublicRoute
            path="/invite-lead-success"
            strict
            exact
            component={AutoSignupSuccess}
          />
          <PublicRoute
            path="/delete-profile/:deleteCode"
            strict
            exact
            component={DeleteAccount}
          />
          <Route
            path="/privacy-policy"
            strict
            exact
            component={PrivacyPolicy}
          />
          <Route
            path="/terms-and-conditions"
            strict
            exact
            component={TermsConditions}
          />
          <Startup />
          <PublicRoute path="*" component={NotFound} />
        </Switch>
      </Suspense>
    </ConfigProvider>
  );
};

const container = document.getElementById('root') as HTMLElement;

const root = ReactDOM.createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Root />
    </BrowserRouter>
  </Provider>,
);

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();
